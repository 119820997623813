import { createSelector } from '@ngrx/store';

import { MetricConfigs } from '@app/metrics';

import { MonitoringState, MonitoringStateContainer } from './monitoring-state.model';

export const selectMonitoringState = (state: MonitoringStateContainer) => state.monitoring;

export const selectKpiDefinitions = createSelector(selectMonitoringState, (state: MonitoringState) => {
    return state.kpiDefinitions;
});

export const selectKpiDefinitionsIds = createSelector(selectKpiDefinitions, (kpiDefinitions) => {
    return kpiDefinitions ? kpiDefinitions.map((kpiDefinition) => kpiDefinition.id) : undefined;
});

export const selectCropKpis = createSelector(selectKpiDefinitionsIds, (kpiDefinitions) => {
    if (!kpiDefinitions) {
        return undefined;
    }

    return MetricConfigs.filter((config) => kpiDefinitions.includes(config.metricId));
});

export const selectCropKpiTargetConfigs = createSelector(selectCropKpis, (metricConfigs) => {
    if (!metricConfigs) {
        return undefined;
    }

    return metricConfigs.map((metricConfig, i) => {
        return {
            kpiId: metricConfig.metricId,
            labelResourceId: metricConfig.label,
            shortLabelResourceId: metricConfig.shortLabel,
            unitResourceId: metricConfig.unit,
            decimals: metricConfig.decimals,
            descriptionResourceId: metricConfig.id,
            categoryId: metricConfig.id,
            orderWithinCategory: i + 1,
        };
    });
});
