import { createSelector } from '@ngrx/store';

import { MetricConfigs } from '@app/metrics';

import { AnalysisState, AnalysisStateContainer } from './analysis-state.model';

export const selectAnalysisState = (state: AnalysisStateContainer) => state.analysis;

export const selectMetricDefinitions = createSelector(selectAnalysisState, (state: AnalysisState) => {
    return state.metricDefinitions;
});

export const selectMetricIds = createSelector(selectMetricDefinitions, (metricDefinitions) => {
    return metricDefinitions ? metricDefinitions.map((definition) => definition.id) : undefined;
});

export const selectCropMetrics = createSelector(selectMetricIds, (metricDefinitions) => {
    if (!metricDefinitions) {
        return undefined;
    }

    return MetricConfigs.filter((config) => metricDefinitions.includes(config.metricId));
});
